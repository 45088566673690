@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/scale.css';

#create_trigger_model div.mb-6, #update_trigger_model div.mb-6 {
  min-width:1100px !important;
  max-width:auto !important;
}

.dropdown-center {
  right: -100%;
}

.toggle-path {
    transition: background 0.3s ease-in-out;
}
.toggle-circle {
    top: 0.2rem;
    left: 0.25rem;
    transition: all 0.3s ease-in-out;
}
input:checked ~ .toggle-circle {
    transform: translateX(100%);
}
input:checked ~ .toggle-path {
    background-color:rgba(52, 211, 153, 1);
    /* background-color:#81E6D9; */
}
